.pregunta {
    color: Black; /* Cambia "blue" al color deseado */
}
  
.respuesta-label {
    color: Gray; /* Cambia "red" al color deseado */
}
  
.container {
display: flex;
justify-content: center;
}

/*.custom-overlay {
    background-color: none;
    backdrop-filter: auto;
    backdrop-invert: 80%;
    backdrop-blur: 2px;
} */

/* .custom-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 40vh;
    
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    outline: transparent solid 2px;
    outline-offset: 2px;
    border-radius: var(--chakra-radii-md);
    background: var(--chakra-colors-white);
    color: inherit;
    margin-top: 3.75rem;
    margin-bottom: 3.75rem;
    z-index: var(--chakra-zIndices-modal);
    box-shadow: var(--chakra-shadows-lg);
    max-width: var(--chakra-sizes-md);
} */


