.info-Reglamentacion-container {
    display: grid;
    gap: 10px;
  }
  
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}