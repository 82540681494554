.container {
    padding-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }
  
.correct-answer {
  color: green;
}

.incorrect-answer {
  color: red;
}